<template>
  <BaseModal
	  :open="open"
	  :hide-title="true"
	  :hide-cancel="!hasAnswered"
	  size="2xl"
	  @submit="handleSubmitNps"
	  :submit-loading="loading"
	  :hide-submit="hasAnswered"
	  :cancel-text="$t('common.buttons.close')"
	  @close="handleClose"
	  :submit-disabled="npsAnswer === null"
  >
	<div class="my-4">
	  <div v-if="!hasAnswered">
		<div class="text-center text-base text-gray-800">
		  {{ $t('pages.nps_question.question', {organisation: authStore.authUser.current_organisation.name}) }}
		</div>

		<div class="mt-12">
		  <div class="flex space-x-8">
			<div class="flex-shrink-0 flex items-end text-sm text-gray-500">
			  <p>
				{{ $t('pages.nps_question.unlikely') }}
			  </p>
			</div>
			<div class="flex-1">
			  <div class="flex justify-between">
				<div v-for="i in 11" class="text-center">
				  <label class="flex-col justify-between">
					<span class="block text-base text-gray-800">{{ i - 1 }}</span>
					<input
						type="radio"
						name="nps"
						v-model="npsAnswer"
						:value="i - 1"
						class="mt-2 h-4 w-4 shrink-0 cursor-pointer border-gray-300 text-blue-600 focus:ring-blue-600 active:ring-2 active:ring-blue-600 active:ring-offset-2"
					>
				  </label>
				</div>
			  </div>
			</div>
			<div class="flex-shrink-0 flex items-end text-sm text-gray-500">
			  <p>
				{{ $t('pages.nps_question.likely') }}
			  </p>
			</div>
		  </div>
		</div>
	  </div>
	  <div v-else class="py-4 px-6 text-center flex flex-col">
		{{ npsResponseText }}
	  </div>
	</div>
  </BaseModal>
</template>

<script>
import BaseModal from "../lib/BaseModal.vue";
import {useAuthStore} from "../../store/auth.js";
import BaseButton from "../lib/input/BaseButton.vue";
import QuestionService from "../../api/QuestionService.js";

export default {
  props: {
	open: {
	  type: Boolean,
	  required: true
	}
  },
  setup() {
	const authStore = useAuthStore();

	return {
	  authStore
	}
  },
  components: {
	BaseButton,
	BaseModal
  },
  data() {
	return {
	  npsAnswer: null,
	  loading: false,
	  hasAnswered: false
	}
  },
  computed: {
	npsResponseText() {
	  if (this.npsAnswer === null) {
		return null;
	  }

	  // For 0-6, we want to show the text for the score
	  if (this.npsAnswer >= 0 && this.npsAnswer <= 6) {
		return this.$t('pages.nps_question.low_score');
	  }

	  // For 7-8
	  if (this.npsAnswer >= 7 && this.npsAnswer <= 8) {
		return this.$t('pages.nps_question.medium_score');
	  }

	  return this.$t('pages.nps_question.high_score');
	}
  },
  methods: {
	handleClose() {
	  if (this.hasAnswered) {
		this.authStore.authUser.should_see_nps_popup = false;
	  }

	  return false;
	},
	handleSubmitNps() {
	  this.loading = true;

	  QuestionService.postNpsAnswer(this.npsAnswer).then(() => {
		this.hasAnswered = true;
	  }).catch(() => {
		this.toastStore.error("An error occurred while submitting your answer");
		this.authStore.authUser.should_see_nps_popup = false;
	  }).finally(() => {
		this.loading = false;
	  });
	}
  }
}
</script>

<style scoped>

</style>