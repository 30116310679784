import auth from "../middleware/auth.js";
import mustCompleteAssessment from "../middleware/mustCompleteAssessment.js";
import canViewOrganisationAnalytics from "../middleware/canViewOrganisationAnalytics.js";

export default [
    {
        path: "/results",
        redirect: "/team-dna",
    },
    {
        path: "/team-dna",
        name: 'results.index',
        component: () => import("../views/auth/results/Index.vue"),
        meta: {
            title: 'Perspective - Results',
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.results',
            auth: true
        },
    },
    {
        path: "/organisation-dna",
        name: 'results.organisation.index',
        component: () => import("../views/auth/results/organisation/Index.vue"),
        meta: {
            title: 'Perspective - Organisation DNA',
            middleware: [auth, mustCompleteAssessment, canViewOrganisationAnalytics],
            heading: 'headings.organisation_results',
            hideTeamName: true,
            auth: true
        },
    },
    {
        path: "/results/team-scores",
        name: 'results.team-scores',
        component: () => import("../views/auth/results/TeamScores.vue"),
        meta: {
            title: 'Perspective - Team Scores',
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.results',
            auth: true
        },
    }
]