<template>
  <div class="min-h-screen">
	<Disclosure
		as="nav" class="bg-white shadow" v-slot="{ open }"
		:key="this.$route.path"
	>
	  <div
		  class="mx-auto px-4 sm:px-6 lg:px-8"
		  :class="[
			  isGuide ? 'max-w-screen-2xl' : 'max-w-7xl'
		  ]"
		  id="nav"
	  >
		<div class="flex justify-between">
		  <div class="flex">
			<div class="flex flex-shrink-0 items-center py-2.5">
			  <RouterLink :to="{
				  name: 'home'
			  }">
				<div class="py-2">
				  <img class="block h-9 w-auto lg:hidden" :src="asset('img/perspective.svg')" alt="Perspective">
				  <img class="hidden h-9 w-auto lg:block" :src="asset('img/perspective.svg')" alt="Perspective">
				</div>
			  </RouterLink>
			</div>
		  </div>
		  <div class="hidden sm:ml-6 md:flex sm:space-x-8">
			<NavLink
				v-for="link in this.navigationLinks"
				:to="link.to"
				:active="link.active()"
				v-show="link.shouldShow()"
			>
			  <component
				  v-bind:is="link.icon"
				  class="mr-1.5 w-6 h-6"
			  />
			  {{ link.name }}
			</NavLink>
		  </div>
		  <div class="hidden md:flex md:items-center">
			<div class="md:flex md:items-center md:space-x-2">
			  <Menu as="div" class="relative"
					v-if="authStore && authStore.authUser && authStore.authUser.teams.length > 1">
				<div>
				  <MenuButton
					  class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-gray-700">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
						 stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
					  <path stroke-linecap="round" stroke-linejoin="round"
							d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"/>
					</svg>
				  </MenuButton>
				</div>
				<transition enter-active-class="transition ease-out duration-200"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
				  <MenuItems
					  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<MenuItem as="div" v-slot="{ active }">
					  <button
						  v-for="team in authStore.authUser.teams"
						  @click="switchTeam(team.id)"
						  class="px-4 py-2 text-sm text-gray-700 flex w-full text-left"
						  :class="[
							  authStore.authUser.current_team.id === team.id ? 'font-semibold cursor-default' : 'cursor-pointer hover:bg-gray-50'
						  ]"
					  >
						{{ team.name }} <span v-if="! team.is_active"
											  class="ml-1">({{
						  this.$t('common.terms.inactive')
						}})</span>
					  </button>
					</MenuItem>
				  </MenuItems>
				</transition>
			  </Menu>
			  <div v-if="authStore.authUser && authStore.authUser.current_team && authStore.authUser.current_team.permissions.viewGuide">
				<RouterLink
					:to="{
						name: 'guide.index'
					}"
				>
				  <HelpIcon
					  class="w-6 h-6"
					  :class="[
						  ['guide.index', 'guide.what-is', 'guide.raw-assessment', 'guide.success'].includes(this.$route.name) ? 'text-blue-500' : 'text-gray-700'
					  ]"
				  />
				</RouterLink>
			  </div>
			  <div>
				<RouterLink
					:to="{
						name: 'announcements.index'
					}"
					class="relative"
				>
				  <AnnouncementIcon
					  class="w-6 h-6"
					  :class="[
						  ['announcements.index', 'announcements.create'].includes(this.$route.name) ? 'text-blue-500' : 'text-gray-700'
					  ]"
				  />
				  <span
					  class="absolute right-1 top-1 h-3 w-3 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-400 ring-2 ring-white text-[0.55rem] text-white flex items-center justify-center"
					  v-if="this.authStore.unreadAnnouncements && this.authStore.unreadAnnouncements.length > 0"
				  >
					  {{ this.authStore.unreadAnnouncements.length }}
				  </span>
				</RouterLink>
			  </div>
			</div>
			<Menu as="div" class="relative ml-4">
			  <div>
				<MenuButton
					class="flex max-w-xs items-center rounded-full bg-white text-xs font-medium tracking-wider focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
				  <img
					  v-if="authStore.authUser.profile_picture"
					  class="h-8 w-8 rounded-full shadow ring-1 ring-gray-300"
					  :src="authStore.authUser.profile_picture.url"
				  />
				  <div
					  class="h-8 w-8 rounded-full shadow bg-blue-500 text-white rounded-full flex items-center justify-center shadow"
					  v-else>
					{{ authStore.authUser.initials }}
				  </div>
				</MenuButton>
			  </div>
			  <transition enter-active-class="transition ease-out duration-200"
						  enter-from-class="transform opacity-0 scale-95"
						  enter-to-class="transform opacity-100 scale-100"
						  leave-active-class="transition ease-in duration-75"
						  leave-from-class="transform opacity-100 scale-100"
						  leave-to-class="transform opacity-0 scale-95">
				<MenuItems
					class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
				  <MenuItem as="div" v-slot="{ active }">
					<a
						v-if="authStore.authUser.permissions?.viewNova"
						href="/nova"
						class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
					>
					  Admin dashboard
					</a>
					<RouterLink
						v-if="this.authStore.authUser.permissions && this.authStore.authUser.permissions.manageOrganisations"
						class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
						:to="{
							name: 'organisations.index'
						}"
					>
					  {{ this.$t("other.navigation.organisations") }}
					</RouterLink>
					<a class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-50"
					   @click="attemptLogout">
					  {{ $t('other.navigation.sign_out') }}
					</a>
				  </MenuItem>
				</MenuItems>
			  </transition>
			</Menu>
		  </div>
		  <div class="-mr-2 flex items-center md:hidden space-x-1">
			<Menu as="div" class="relative mr-2"
				  v-if="authStore && authStore.authUser && authStore.authUser.teams.length > 1">
			  <div>
				<MenuButton
					class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-gray-700">
				  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
					   stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
					<path stroke-linecap="round" stroke-linejoin="round"
						  d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"/>
				  </svg>
				</MenuButton>
			  </div>
			  <transition enter-active-class="transition ease-out duration-200"
						  enter-from-class="transform opacity-0 scale-95"
						  enter-to-class="transform opacity-100 scale-100"
						  leave-active-class="transition ease-in duration-75"
						  leave-from-class="transform opacity-100 scale-100"
						  leave-to-class="transform opacity-0 scale-95">
				<MenuItems
					class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
				  <MenuItem as="div" v-slot="{ active }">
					<button
						v-for="team in authStore.authUser.teams"
						@click="switchTeam(team.id)"
						class="px-4 py-2 text-sm text-gray-700 flex w-full text-left"
						:class="[
							authStore.authUser.current_team?.id === team.id ? 'font-semibold cursor-default' : 'cursor-pointer hover:bg-gray-50'
						]"
					>
					  {{ team.name }} <span v-if="! team.is_active"
											class="ml-1">
                                            ({{
						this.$t('common.terms.inactive')
					  }})
                                        </span>
					</button>
				  </MenuItem>
				</MenuItems>
			  </transition>
			</Menu>
			<RouterLink
				:to="{
					name: 'announcements.index'
				}"
				class="relative"
			>
			  <AnnouncementIcon
				  class="w-6 h-6"
				  :class="[
										  ['announcements.index', 'announcements.create'].includes(this.$route.name) ? 'text-blue-500' : 'text-gray-700'
									  ]"
			  />
			  <span
				  class="absolute right-1 top-1 h-3 w-3 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-400 ring-2 ring-white text-[0.55rem] text-white flex items-center justify-center"
				  v-if="this.authStore.unreadAnnouncements && this.authStore.unreadAnnouncements.length > 0"
			  >
									  {{ this.authStore.unreadAnnouncements.length }}
								  </span>
			</RouterLink>
			<DisclosureButton
				class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
			  <span class="sr-only">Open main menu</span>
			  <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true"/>
			  <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true"/>
			</DisclosureButton>
		  </div>
		</div>
	  </div>

	  <div class="md:hidden" id="mobile-menu" v-if="open">
		<div class="space-y-1 pt-2 pb-3">
		  <NavLink
			  v-for="link in this.navigationLinks"
			  :to="link.to"
			  :active="link.active()"
			  v-show="link.shouldShow()"
			  :mobile="true"
		  >
			{{ link.name }}
		  </NavLink>
		</div>
		<div class="border-t border-gray-200 pt-4 pb-3">
		  <div class="flex items-center px-4">
			<div class="flex-shrink-0">
			  <div
				  class="h-10 w-10 rounded-full bg-blue-500 text-white flex items-center justify-center ring-1 ring-blue-400 shadow"
				  v-if="! authStore.authUser.profile_picture"
			  >
				{{ authStore.authUser.initials }}
			  </div>
			  <img
				  v-if="authStore.authUser.profile_picture"
				  class="h-10 w-10 rounded-full border border-gray-100"
				  :src="authStore.authUser.profile_picture.url"
				  alt=""
			  />
			</div>
			<div class="ml-3">
			  <div class="text-base font-medium text-gray-800">{{ authStore.authUser.name }}</div>
			  <div class="text-sm font-medium text-gray-500">{{ authStore.authUser.email }}</div>
			</div>
		  </div>
		  <div class="mt-3 space-y-1">
			<RouterLink
				v-if="this.authStore.authUser.permissions && this.authStore.authUser.permissions.manageOrganisations"
				class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
				:to="{
					name: 'organisations.index'
				}"
			>
			  {{ this.$t("other.navigation.organisations") }}
			</RouterLink>
			<a
				@click="attemptLogout"
				class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
			>
			  {{ $t('other.navigation.sign_out') }}
			</a>
		  </div>
		</div>
	  </div>
	</Disclosure>
	<div class="py-6 bg-white border-t shadow" id="sub-nav" v-if="this.$route.name !== 'not-found'">
	  <div
		  class="mx-auto px-4 sm:px-6 lg:px-8"
		  :class="[
			  isGuide ? 'max-w-screen-2xl' : 'max-w-7xl'
		  ]"
	  >
		<div class="sm:flex items-center md:justify-between">
		  <div class="min-w-0 flex-1">
			<h2
				class="text-xl text-gray-900 mt-1 font-medium inline-flex items-center"
			>
			  <span v-if="!this.$route.meta.hideTeamName" class="text-gray-400">
				  {{ this.authStore?.authUser?.current_team?.formatName() }} team
				  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
					   stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-flex ml-1">
					<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
				  </svg>
			  </span>
			  <span
				  :class="[
					!this.$route.meta.hideTeamName ? 'ml-1' : ''
				]"
			  >{{ this.pageHeading }}</span>
			</h2>
		  </div>
		  <div
			  v-if="[
				  'results.index',
				  'results.team-scores',
			  ].includes(this.$route.name)"
			  class="mt-4 flex md:mt-0"
		  >
			<!--                         md:ml-4 min-w-[15rem] -->
			<MetricsPageSelector/>
		  </div>
		</div>
	  </div>
	</div>
	<div
		class="mx-auto sm:px-6 lg:px-8 py-6 relative"
		:class="[
			isGuide ? 'max-w-screen-2xl' : 'max-w-7xl'
		]"
	>
	  <div class="px-4 md:px-0" v-if="authStore.authUser && authStore.authUser.current_team && authStore.authUser.current_team.inactive() && this.canShowBanner">
		<div class="rounded-md bg-yellow-50 border border-yellow-700 p-4 mb-4">
		  <div class="flex">
			<div class="flex-shrink-0 mt-1">
			  <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor"
				   aria-hidden="true">
				<path fill-rule="evenodd"
					  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
					  clip-rule="evenodd"/>
			  </svg>
			</div>
			<div class="ml-3">
			  <h3 class="font-semibold text-yellow-800">
				{{ $t("common.team_inactive.heading") }}
			  </h3>
			  <div class="text-yellow-700 mt-0.5">
				<p>{{ $t("common.team_inactive.subheading") }}</p>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  <slot v-if="authStore.authUser"/>
	</div>
	<ToastList
		v-if="!authStore.loggingOut"
	/>
  </div>

  <LandingInformationModal
	  :open="authStore.authUser.should_see_welcome_popup"
  />

  <EmployeeNpsModal
	  :open="authStore.authUser.should_see_nps_popup && authStore.authUser.should_see_welcome_popup === false"
  />

  <DailyQuestionModal
	  :open="authStore.authUser.daily_question && authStore.authUser.should_see_welcome_popup === false && authStore.authUser.should_see_nps_popup === false"
  />
</template>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import ChallengesIcon from "../icons/ChallengesIcon.vue";
import ActivityIcon from "../icons/ActivityIcon.vue";
import ResultsIcon from "../icons/ResultsIcon.vue";
import OrganisationsIcon from "../icons/OrganisationsIcon.vue";
import NavLink from "./navigation/NavLink.vue";
import {useAuthStore} from "../../store/auth.js";
import TeamsIcon from "../icons/TeamsIcon.vue";
import AccountService from "../../api/AccountService.js";
import AuthService from "../../api/AuthService.js";
import User from "../../models/User.js";
import {markRaw} from "vue";
import ToastList from "../toast/ToastList.vue";
import BaseButton from "../lib/input/BaseButton.vue";
import DailyQuestionModal from "../daily_question/DailyQuestionModal.vue";
import {useToastStore} from "../../store/toast.js";
import MetricsPageSelector from "../metrics/MetricsPageSelector.vue";
import HelpIcon from "../icons/HelpIcon.vue";
import BaseAlert from "../BaseAlert.vue";
import AnnouncementIcon from "../icons/AnnouncementIcon.vue";
import LandingInformationModal from "../landing/LandingInformationModal.vue";
import EmployeeNpsModal from "../nps/EmployeeNpsModal.vue";
import * as Sentry from "@sentry/vue";

export default {
  setup() {
	const authStore = useAuthStore();
	const toastStore = useToastStore();

	return {
	  authStore,
	  toastStore
	}
  },
  components: {
	EmployeeNpsModal,
	LandingInformationModal,
	AnnouncementIcon,
	BaseAlert,
	HelpIcon,
	MetricsPageSelector,
	DailyQuestionModal,
	BaseButton,
	ToastList,
	NavLink,
	OrganisationsIcon: markRaw(OrganisationsIcon),
	ResultsIcon: markRaw(ResultsIcon),
	ActivityIcon: markRaw(ActivityIcon),
	TeamsIcon: markRaw(TeamsIcon),
	ChallengesIcon: markRaw(ChallengesIcon),
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Bars3Icon,
	BellIcon,
	XMarkIcon
  },
  computed: {
	isGuide() {
	  return this.$route.path.includes('/guide');
	},
	navigationLinks() {
	  return [
		{
		  icon: ActivityIcon,
		  name: this.$t("other.navigation.home"),
		  to: 'home',
		  shouldShow: () => {
			return true;
		  },
		  active: () => {
			return this.$route.name === 'home'
		  }
		},
		{
		  icon: ChallengesIcon,
		  name: this.$t("other.navigation.challenges"),
		  to: 'challenges.index',
		  shouldShow: () => {
			return this.authStore.authUser.hasCurrentTeam();
		  },
		  active: () => {
			return [
			  'challenges.index',
			  'challenges.create',
			  'challenges.show',
			  'challenges.preview',
			  'challenges.vote',
			  'session.check-in',
			  'session.previous-challenge',
			  'session.prioritised-challenge',
			  'session.driving-prioritised-challenge',
			  'session.team-capabilities',
			  'session.actions',
			  'session.summary',
			].includes(this.$route.name)
		  }
		},
		{
		  icon: ResultsIcon,
		  name: this.$t("other.navigation.results"),
		  to: 'results.index',
		  shouldShow: () => {
			return this.authStore.authUser.hasCurrentTeam();
		  },
		  active: () => {
			return ['results.index', 'results.team-scores', 'results.outcomes'].includes(this.$route.name)
		  }
		},
		{
		  icon: ResultsIcon,
		  name: this.$t("other.navigation.organisation_results"),
		  to: 'results.organisation.index',
		  shouldShow: () => {
			return this.authStore.authUser.hasCurrentOrganisation() && this.authStore.authUser.canViewOrganisationAnalytics();
		  },
		  active: () => {
			return ['results.organisation.index'].includes(this.$route.name)
		  }
		},
		{
		  icon: TeamsIcon,
		  name: this.$t("other.navigation.team"),
		  to: 'team.index',
		  shouldShow: () => {
			return this.authStore.authUser.hasCurrentTeam();
		  },
		  active: () => {
			return this.$route.name === 'team.index'
		  }
		}
	  ]
	},
	pageHeading() {
	  if (this.$route.name === 'home') {
		return this.$t('common.terms.welcome') + ', ' + this.authStore.authUser?.firstNameOrName();
	  }

	  return this.$t(this.$route.meta.heading);
	},
	canShowBanner() {
	  return ![
		'organisations.index', 'organisations.show', 'teams.show'
	  ].includes(this.$route.name);
	}
  },
  methods: {
	switchTeam(teamId) {
	  if (teamId === this.authStore.authUser.current_team.id) {
		return true;
	  }

	  AccountService.postSwitchTeam(teamId).then((response) => {

		// Replace page when switching team, to prevent loading pages that don't exist
		if (this.$route.query && this.$route.query.page) {
		  this.$router.replace({name: this.$route.name});
		}

		AuthService.getMe().then((response) => {
		  this.authStore.authUser = new User(response.data.data);
		  this.toastStore.success(this.$t('common.actions.switched_team', {team: this.authStore.authUser.current_team.formatName()}));
		});
	  })
	},
	attemptLogout() {
	  this.authStore.logout().then(() => {
		try {
		  Sentry.setUser(null);
		} catch (e) {
		  console.log(e);
		}

		this.$router.push({name: 'authentication.login'});
	  });
	}
  }
}
</script>
