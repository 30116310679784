import auth from "../middleware/auth.js";
import manageOrganisations from "../middleware/manageOrganisations.js";

export default [
    {
        path: "/organisations",
        name: 'organisations.index',
        component: () => import("../views/auth/organisations/Index.vue"),
        meta: {
            heading: 'Organisations',
            hideTeamName: true,
            middleware: [auth, manageOrganisations],
            auth: true
        }
    },
    {
        path: "/organisations/:id",
        name: 'organisations.show',
        component: () => import("../views/auth/organisations/Show.vue"),
        meta: {
            heading: 'Organisations',
            hideTeamName: true,
            middleware: [auth, manageOrganisations],
            auth: true
        }
    },
    {
        path: "/organisations/:id/import",
        name: 'organisations.import',
        component: () => import("../views/auth/organisations/import/Index.vue"),
        meta: {
            heading: 'Import teams and users',
            hideTeamName: true,
            middleware: [auth, manageOrganisations],
            auth: true
        }
    },
    {
        path: "/organisations/:id/import/:uuid/users",
        name: 'organisations.import.users',
        component: () => import("../views/auth/organisations/import/Users.vue"),
        meta: {
            heading: 'Import teams and users',
            hideTeamName: true,
            middleware: [auth, manageOrganisations],
            auth: true
        }
    }
]
